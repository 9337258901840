import React, {
    useContext,
    useMemo
} from "react";
import {
    useRouteMatch
} from "react-router-dom";
import {
    Sidebar,
    SidebarDivider,
    SidebarLink
} from "@zandor300/backoffice-framework";

import Logo from "../Logo";
import usePermissions from "../../hooks/usePermissions";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function GridSenseSidebar() {
    const authentication = useContext(AuthenticatedUserContext);
    const permissions = usePermissions();
    const installationId = useMemo(() => {
        const {
            user
        } = authentication;
        if(!user) {
            return null;
        }
        if(user.installationsCount === 1 && user.singleInstallationId) {
            return user.singleInstallationId;
        }
    }, [authentication]);

    const installationsMatch = useRouteMatch({
        path: [
            "/installations",
            "/installation/:installationId",
        ],
    });
    const gatewaysMatch = useRouteMatch({
        path: [
            "/gateways",
            "/gateway/:gatewayUuid",
        ],
    });

    return (
        <Sidebar
            logo={
                <Logo white/>
            }
        >
            <SidebarLink to="/dashboard" icon="fa-solid fa-gauge-high">
                Dashboard
            </SidebarLink>
            <SidebarLink to="/energy-prices" icon="fa-solid fa-meter-bolt">
                Energieprijs
            </SidebarLink>
            { permissions.globalAdmin && (
                <SidebarLink to="/imbalance-prices" icon="fa-solid fa-scale-unbalanced">
                    Onbalansprijs
                </SidebarLink>
            )}
            { installationId ? (
                <SidebarLink
                    to={ "/installation/" + installationId }
                    icon="fa-solid fa-solar-panel"
                    active={installationsMatch !== null}
                >
                    Installatie
                </SidebarLink>
            ) : (
                <SidebarLink
                    to="/installations"
                    icon="fa-solid fa-solar-panel"
                    active={installationsMatch !== null}
                >
                    Installaties
                </SidebarLink>
            )}
            { permissions.globalAdmin && (
                <React.Fragment>
                    <SidebarLink to="/alerts" icon="fa-solid fa-triangle-exclamation">
                        Alerts
                    </SidebarLink>
                    <SidebarDivider/>
                    <SidebarLink
                        to="/gateways"
                        icon="fa-solid fa-router"
                        active={gatewaysMatch !== null}
                    >
                        Gateways
                    </SidebarLink>
                    <SidebarLink to="/energy-handling-modes" icon="fa-solid fa-sliders">
                        Energiesturing
                    </SidebarLink>
                    <SidebarLink to="/energy-price-delta" icon="fa-solid fa-wave-square">
                        Prijsdelta
                    </SidebarLink>
                    <SidebarLink to="/subscriptions" icon="fa-solid fa-money-check-dollar">
                        Abonnementen
                    </SidebarLink>
                    <SidebarLink to="/transactions" icon="fa-solid fa-money-bill-transfer">
                        Transacties
                    </SidebarLink>
                </React.Fragment>
            )}
            <SidebarDivider/>
            { permissions.manageCompanyUsers && (
                <SidebarLink to="/users" icon="fa-solid fa-users" additionalUrls={["/user"]}>
                    Gebruikers
                </SidebarLink>
            )}
            <SidebarLink to="/settings" icon="fa-solid fa-gear">
                Instellingen
            </SidebarLink>
        </Sidebar>
    );
}

export default React.memo(GridSenseSidebar);
