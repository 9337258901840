import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal
} from "react-bootstrap";

function EVChargerModeModal({ evCharger, show, handleClose, refreshEVChargers }) {
    const [evChargerModes, setEVChargerModes] = useState(null);
    const [state, setState] = useState(undefined);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useCallback(async () => {
        if(!evCharger) {
            return;
        }
        setError(null);
        setSaving(false);
        if(evCharger.mode) {
            setState(evCharger.mode.value);
        } else {
            setState(null);
        }

        try {
            const response = await axios.get("/getEVChargerModes");
            setEVChargerModes(response.data.modes);
        } catch(error) {
            console.error(error);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [evCharger]);
    const submit = useCallback(async () => {
        setSaving(true);
        try {
            await axios.post("/setEVChargerMode", {
                evChargerId: evCharger.id,
                mode: state
            });
            setSaving(false);
            refreshEVChargers();
            handleClose();
        } catch(error) {
            console.error(error);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            setSaving(false);
        }
    }, [evCharger, state, refreshEVChargers, handleClose]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Laadpaal sturing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p>
                    Hier kun je de sturing van de laadpaal veranderen.
                </p>
                <Form.Control
                    as="select"
                    value={ state }
                    onChange={ (event) => setState(event.target.value) }
                    disabled={ saving || !evChargerModes }
                >
                    { !evCharger?.mode && (
                        <option value=""/>
                    )}
                    { evChargerModes && evChargerModes.map((evChargerMode) => (
                        <option
                            key={ evChargerMode.value }
                            value={ evChargerMode.value }
                            style={{ backgroundColor: evChargerMode.color, color: "white" }}
                        >
                            { evChargerMode.name }
                        </option>
                    )) }
                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={ submit } disabled={ saving }>
                    Toepassen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(EVChargerModeModal);
