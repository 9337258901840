const HistoryResolution = {
    FIVE_MINUTES: {
        value: "5m",
        label: "5 minuten",
        adjustEnergy: 60 / 5,
    },
    TEN_MINUTES: {
        value: "10m",
        label: "10 minuten",
        adjustEnergy: 60 / 10,
    },
    FIFTEEN_MINUTES: {
        value: "15m",
        label: "15 minuten",
        adjustEnergy: 4,
    },
    THIRTY_MINUTES: {
        value: "30m",
        label: "30 minuten",
        adjustEnergy: 2,
    },
    ONE_HOUR: {
        value: "1h",
        label: "1 uur",
        adjustEnergy: 1,
    },
    ONE_DAY: {
        value: "1d",
        label: "1 dag",
        adjustEnergy: 1 / 24,
    },
}

export default HistoryResolution;
