import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment-timezone";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/charts/ChartLoading";
import ChartNoData from "../components/charts/ChartNoData";
import NumberFormatter from "../components/formatters/NumberFormatter";

function ChartEnergyPrices({ startDate, endDate, installationId, updatePrices }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const currentHourString = useMemo(() => {
        return moment().format("YYYY-MM-DD HH");
    }, []);

    const getPrices = useCallback(() => {
        if(!startDate || !endDate) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setError(null);
        let parameters = {
            startDate: startDateFormat,
            endDate: endDateFormat
        }
        if(installationId) {
            parameters.installationId = installationId;
        }
        const url = "/getTennetBalanceDelta";
        updatePrices?.(null);
        axios.post(url, parameters)
            .then((response) => {
                if(response.data.valid) {
                    if(!response.data.prices || response.data.prices.length === 0) {
                        setData([]);
                        return;
                    }
                    updatePrices?.(response.data.prices);
                    setData([
                        {
                            label: "Balansdelta invoeden",
                            data: response.data.prices.map((priceDatum) => ({
                                key: moment(priceDatum.timeIntervalStart).format("YYYY-MM-DD HH:mm:ss"),
                                value: priceDatum.maxUpwardsRegulationPrice ?? priceDatum.minDownwardsRegulationPrice ?? priceDatum.midPrice
                            }))
                        },
                        {
                            label: "Balansdelta afnemen",
                            data: response.data.prices.map((priceDatum) => ({
                                key: moment(priceDatum.timeIntervalStart).format("YYYY-MM-DD HH:mm:ss"),
                                value: priceDatum.minDownwardsRegulationPrice ?? priceDatum.maxUpwardsRegulationPrice ?? priceDatum.midPrice
                            }))
                        },
                    ]);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [startDate, endDate, installationId, updatePrices]);

    useEffect(() => {
        setData(null);
        getPrices();
    }, [getPrices]);
    useEffect(() => {
        const timer = setInterval(getPrices, 15 * 1000);
        return () => clearInterval(timer);
    }, [getPrices]);

    const minimumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = -20;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue -= 10;
                }
                workingValue = Math.min(workingValue, comparedValue);
            });
        });
        return workingValue;
    }, [data]);
    const maximumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = 30;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue += 10;
                }
                workingValue = Math.max(workingValue, comparedValue);
            });
        });
        return Math.ceil(workingValue * 100) / 100;
    }, [data]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm")
        },
        min: moment(startDate).toDate(),
        max: moment(endDate).toDate()
    }), [startDate, endDate]);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => {
            return datum.value;
        },
        formatters: {
            scale: (value) => (
                <NumberFormatter
                    number={ value }
                    minimumFractionDigits={ 0 }
                />
            ),
            tooltip: (value) => (
                <NumberFormatter
                    number={ value }
                    minimumFractionDigits={ 2 }
                />
            )
        },
        min: minimumValue,
        max: maximumValue
    }], [minimumValue, maximumValue]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                getDatumStyle: (datum) => {
                    if(moment(datum.originalDatum.key).format("YYYY-MM-DD HH") !== currentHourString) {
                        return {}
                    }
                    return {
                        color: "#40bfff"
                    }
                }
            }}
        />
    )
}

export default React.memo(ChartEnergyPrices);
