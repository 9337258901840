import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title,
    useWindowSize
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

import Helmet from "../../../components/Helmet";
import BottomTabBar from "../../../components/tabbar-bottom/BottomTabBar";
import BottomTabBarLink from "../../../components/tabbar-bottom/BottomTabBarLink";

import InstallationDetailOverview from "./InstallationDetailOverview";
import InstallationDetailGateways from "./InstallationDetailGateways";
import InstallationDetailDevices from "./InstallationDetailDevices";
import InstallationDetailAlerts from "./InstallationDetailAlerts";
import InstallationDetailAccess from "./InstallationDetailAccess";
import InstallationDetailEdit from "./InstallationDetailEdit";

function InstallationDetail({ match }) {
    const [installation, setInstallation] = useState(null);
    const [error, setError] = useState(null);
    const windowSize = useWindowSize();

    const installationId = useMemo(() => {
        return match.params.installationId ? parseInt(match.params.installationId) : null;
    }, [match.params.installationId]);
    const refreshInstallation = useMemo(() => {
        return () => {
            setInstallation(null);
            setError(null);
            axios.post("/getInstallation", { installationId: installationId })
                .then((response) => {
                    if(response.data.valid) {
                        setInstallation(response.data.installation);
                    } else {
                        setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((requestError) => {
                    console.error(requestError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                });
        }
    }, [installationId]);
    useEffect(() => {
        refreshInstallation();
    }, [refreshInstallation]);

    const baseUrl = `/installation/${ installationId }`;

    const childProps = {
        installation,
        installationId,
        refreshInstallation,
        setInstallation
    };

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    return (
        <React.Fragment>
            <Helmet title={ installation?.name ?? "Installatie" }/>
            <Title preTitle="Installatie" noBottom={ windowSize.width >= 576 }>
                { !installation ? (
                    <Skeleton width={ 300 }/>
                ) : (
                    installation.name
                )}
            </Title>
            <TabBar className="d-none d-sm-flex">
                <TabBarItem to={ baseUrl }>
                    Overzicht
                </TabBarItem>
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <TabBarItem to={ `${baseUrl}/gateways` }>
                        Gateways
                    </TabBarItem>
                )}
                <TabBarItem to={ `${baseUrl}/devices` }>
                    Apparaten
                </TabBarItem>
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <TabBarItem to={ `${baseUrl}/alerts` }>
                        Alerts
                    </TabBarItem>
                )}
                { installation?.userRole?.permissions.manageInstallationAccess && (
                    <TabBarItem to={ `${baseUrl}/access` }>
                        Toegang
                    </TabBarItem>
                )}
                { installation?.userRole?.permissions.manageInstallations && (
                    <TabBarItem to={ `${baseUrl}/settings` }>
                        Instellingen
                    </TabBarItem>
                )}
            </TabBar>
            <BottomTabBar className="d-sm-none">
                <BottomTabBarLink
                    to={ baseUrl }
                    title="Overzicht"
                    icon="fa-solid fa-home"
                    exact
                />
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <BottomTabBarLink
                        to={ `${baseUrl}/gateways` }
                        title="Gateways"
                        icon="fa-solid fa-router"
                        exact
                    />
                )}
                <BottomTabBarLink
                    to={ `${baseUrl}/devices` }
                    title="Apparaten"
                    icon="fa-solid fa-solar-panel"
                    exact
                />
                { installation?.userRole?.permissions.manageInstallationAccess && (
                    <BottomTabBarLink
                        to={ `${baseUrl}/access` }
                        title="Toegang"
                        icon="fa-solid fa-users"
                        exact
                    />
                )}
                { installation?.userRole?.permissions.manageInstallations && (
                    <BottomTabBarLink
                        to={ `${baseUrl}/settings` }
                        title="Instellingen"
                        icon="fa-solid fa-cog"
                        exact
                    />
                )}
            </BottomTabBar>
            <Switch>
                <Route path={ baseUrl } exact>
                    <InstallationDetailOverview { ...childProps }/>
                </Route>
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <Route path={ `${baseUrl}/gateways` } exact>
                        <InstallationDetailGateways { ...childProps }/>
                    </Route>
                )}
                <Route path={ `${baseUrl}/devices` } exact>
                    <InstallationDetailDevices { ...childProps }/>
                </Route>
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <Route path={ `${baseUrl}/alerts` } exact>
                        <InstallationDetailAlerts { ...childProps }/>
                    </Route>
                )}
                { installation?.userRole?.permissions.manageInstallationAccess && (
                    <Route path={ `${baseUrl}/access` } exact>
                        <InstallationDetailAccess { ...childProps }/>
                    </Route>
                )}
                { installation?.userRole?.permissions.manageInstallations && (
                    <Route path={ `${baseUrl}/settings` } exact>
                        <InstallationDetailEdit { ...childProps }/>
                    </Route>
                )}
            </Switch>
        </React.Fragment>
    )
}

export default React.memo(InstallationDetail);
