import React, {
    useMemo,
    useRef,
    useState
} from "react";
import {
    Dropdown,
    Overlay
} from "react-bootstrap";

import StatusDot from "../../../../../components/StatusDot";
import isNullOrUndefined from "@gridsense/react-energy-flow/lib/utilities/isNullOrUndefined";

function stateToColor(state) {
    switch(state) {
        case true:
            return "#198754";
        case false:
            return "#dc3545";
        case null:
            return "#0d6efd";
        default:
            return "#6c757d";
    }
}

function RelayStateSetting({ state, desiredState, index, loading, onSet }) {
    const [show, setShow] = useState(false);
    const ref = useRef();

    const allowEdit = useMemo(() => {
        return !isNullOrUndefined(onSet);
    }, [onSet])

    return (
        <div className="position-relative">
            <StatusDot
                ref={ref}
                name={`Relais ${index + 1} - ${state ? "Aan" : "Uit"}`}
                color={stateToColor(state)}
                style={{
                    width: "2rem",
                    height: "2rem",
                    fontSize: "1rem",
                    opacity: loading ? 0.5 : 1,
                    outline: `3px solid ${stateToColor(desiredState)}`,
                    outlineOffset: "-3px",
                }}
                className={allowEdit && "pointer-cursor"}
                onClick={() => setShow(!show)}
            >
                {index + 1}
            </StatusDot>
            <Overlay
                show={show && allowEdit}
                target={ref.current}
                placement="bottom-start"
                transition={false}
                rootClose
                rootCloseEvent="click"
                onHide={() => setShow(false)}
            >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        className={"dropdown-menu show" + (props.className ? ` ${props.className}` : "")}
                        style={{
                            zIndex: 1090,
                            ...props.style,
                        }}
                    >
                        <Dropdown.Item
                            onClick={() => onSet(true)}
                            active={desiredState === true}
                        >
                            <i className={"fa-solid fa-fw mr-2" + (desiredState === true ? " fa-check" : "") }/>
                            Aan
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => onSet(false)}
                            active={desiredState === false}
                        >
                            <i className={"fa-solid fa-fw mr-2" + (desiredState === false ? " fa-check" : "")}/>
                            Uit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => onSet(null)}
                            active={desiredState === null}
                        >
                            <i className={"fa-solid fa-fw mr-2" + (desiredState === null ? " fa-check" : "")}/>
                            Automatisch
                        </Dropdown.Item>
                    </div>
                )}
            </Overlay>
        </div>
    );
}

export default React.memo(RelayStateSetting);
