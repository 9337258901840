import React, {
    useEffect, useMemo,
    useRef,
    useState
} from "react";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

import Loading from "../../../../components/Loading";

function EVChargerScheduleItem({ scheduleItem, hideLabels, minimumWidthPercentageShowLabels, timeFormat = "HH:mm", rotateLabels, displayStartDate, displayEndDate }) {
    const selectedStartDate = useMemo(() => {
        if(!scheduleItem) {
            return null;
        }
        const displayStartDateMoment = moment(displayStartDate);
        const itemStartDateMoment = moment(scheduleItem.startDate);
        return itemStartDateMoment.isBefore(displayStartDateMoment) ? displayStartDateMoment : itemStartDateMoment;
    }, [scheduleItem, displayStartDate]);
    const selectedEndDate = useMemo(() => {
        if(!scheduleItem) {
            return null;
        }
        const displayEndDateMoment = moment(displayEndDate);
        const itemEndDateMoment = moment(scheduleItem.endDate);
        return itemEndDateMoment.isAfter(displayEndDateMoment) ? displayEndDateMoment : itemEndDateMoment;
    }, [scheduleItem, displayEndDate]);

    const widthPercentage = useMemo(() => {
        if(!selectedStartDate || !selectedEndDate) {
            return null;
        }
        const duration = selectedEndDate.diff(selectedStartDate, "seconds");
        return duration / 86400 * 100;
    }, [selectedStartDate, selectedEndDate]);

    return (
        <OverlayTrigger overlay={
            <Tooltip id={`tooltip-EnergyHandlingModeItem`}>
                <b>
                    { selectedStartDate.format(timeFormat) }
                    {" - "}
                    { selectedEndDate.format(timeFormat) }
                </b>
                <br/>
                { scheduleItem.enableCharging ? "Laden" : "Pauze" }
            </Tooltip>
        }>
            <div
                className="energy-handling-item-container"
                style={{
                    width: `${widthPercentage}%`
                }}
            >
                <div
                    className="energy-handling-item-block"
                    style={{
                        backgroundColor: scheduleItem.enableCharging ? "#007bff" : "#6c757d"
                    }}
                />
                { (!hideLabels && widthPercentage >= minimumWidthPercentageShowLabels) && (
                    <div
                        className={"energy-handling-item-text text-left" + (rotateLabels ? " rotate" : "")}
                    >
                        {selectedStartDate.format(timeFormat)}
                    </div>
                )}
            </div>
        </OverlayTrigger>
    )
}

function EVChargerScheduleChart({ schedule, displayStartDate, displayEndDate, unavailable, hideLabels }) {
    const chartRef = useRef();
    const windowSize = useWindowSize();
    const [rotateLabels, setRotateLabels] = useState(false);
    const [minimumWidthPercentageShowLabels, setMinimumWidthPercentageShowLabels] = useState(10);

    useEffect(() => {
        if(!chartRef.current) {
            return;
        }
        const chartWidth = chartRef.current.offsetWidth;
        const minimumWidthPixels = 40;
        const minimumWidthPercentage = minimumWidthPixels / chartWidth * 100;
        setMinimumWidthPercentageShowLabels(minimumWidthPercentage);
    }, [windowSize]);

    useEffect(() => {
        if(!chartRef.current) {
            setRotateLabels(false);
            return;
        }
        if(chartRef.current.offsetWidth >= 800) {
            setRotateLabels(false);
            return;
        }
        setRotateLabels(false);
    }, [chartRef, windowSize]);

    const startOffsetWidth = useMemo(() => {
        if(!schedule || schedule.length === 0) {
            return null;
        }
        const displayStartDateMoment = moment(displayStartDate);
        const firstItemStartDate = moment(schedule[0].startDate);
        const difference = firstItemStartDate.diff(displayStartDateMoment, "seconds");
        return difference / 86400 * 100;
    }, [schedule, displayStartDate]);

    if(unavailable) {
        return (
            <p className="m-0">
                Geen sturing beschikbaar.
            </p>
        );
    }
    if(!schedule) {
        return (
            <Loading/>
        );
    }
    return (
        <div ref={ chartRef } className="energy-handling-chart-bar">
            <div
                style={{
                    width: `${startOffsetWidth}%`
                }}
            />
            { schedule.map((scheduleItem, index) => {
                const itemStartDate = moment(scheduleItem.startDate);
                const displayEndDateMoment = moment(displayEndDate);
                if(itemStartDate >= displayEndDateMoment) {
                    return null;
                }
                return (
                    <EVChargerScheduleItem
                        key={ index }
                        scheduleItem={ scheduleItem }
                        hideLabels={ hideLabels }
                        minimumWidthPercentageShowLabels={ minimumWidthPercentageShowLabels }
                        timeFormat="HH:mm"
                        rotateLabels={ rotateLabels }
                        displayStartDate={ displayStartDate }
                        displayEndDate={ displayEndDate }
                    />
                );
            })}
        </div>
    );
}

export default React.memo(EVChargerScheduleChart);
