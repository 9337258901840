import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";
import EVChargerScheduleChart from "../EVChargerScheduleChart";

function InstallationEVChargerScheduleCard({ installation, startDate, endDate }) {
    const [chargingSchedules, setChargingSchedules] = useState(null);
    const [error, setError] = useState(null);

    const getSchedules = useCallback(async () => {
        setChargingSchedules(null);
        setError(null);
        try {
            const response = await axios.post("/getChargingSchedulesForInstallation", {
                installationId: installation.id,
                startDate: startDate.tz("Europe/Amsterdam").toISOString(),
                endDate: endDate.tz("Europe/Amsterdam").toISOString(),
            });
            setChargingSchedules(response.data.chargingSchedules);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [installation, startDate, endDate]);

    useEffect(() => {
        getSchedules();
    }, [getSchedules]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">Laadplanning</h5>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !chargingSchedules ? (
                    <Loading/>
                ) : chargingSchedules.map((chargingSchedule) => (
                    <div key={ chargingSchedule.evCharger.id }>
                        <EVChargerScheduleChart
                            schedule={ chargingSchedule.schedule }
                            displayStartDate={ startDate }
                            displayEndDate={ endDate }
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default React.memo(InstallationEVChargerScheduleCard);
