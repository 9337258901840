import React from "react";
import {
    Link
} from "react-router-dom";

import DateFormatter from "../../../components/formatters/DateFormatter";
import AlertStatus from "./AlertStatus";
import AlertBadge from "./AlertBadge";

function AlertTitle({ alert, showInstallation }) {
    return (
        <div className="d-flex flex-row w-100">
            <div className="flex-grow-1">
                { (showInstallation && alert.installation) ? (
                    <React.Fragment>
                        <h4 className="card-title d-flex flex-row align-items-end">
                            { alert.installation.name }
                            <small className="text-muted mx-2">
                                #{ alert.installation.id }
                            </small>
                            <div>
                                <Link
                                    to={ `/installation/${ alert.installation.id }/alerts` }
                                    style={{ fontSize: "0.85rem" }}
                                >
                                    <i className="fa-solid fa-external-link"/>
                                </Link>
                            </div>
                        </h4>
                        { alert.inverter && (
                            <h6 className="card-title">
                                { alert.inverter.manufacturer }{" "}
                                <br className="d-md-none"/>
                                { alert.inverter.model }
                                <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                    { alert.inverter.serialNumber }
                                </div>
                            </h6>
                        )}
                    </React.Fragment>
                ) : alert.inverter && (
                    <h5 className="card-title">
                        { alert.inverter.manufacturer }{" "}
                        <br className="d-md-none"/>
                        { alert.inverter.model }
                        <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                            { alert.inverter.serialNumber }
                        </div>
                    </h5>
                )}
            </div>
            <div>
                <AlertStatus alert={ alert }/>
            </div>
        </div>
    );
}

function AlertCard({ alert, showInstallation }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <AlertTitle
                    alert={ alert }
                    showInstallation={ showInstallation }
                />
                <p className="card-text">
                    { alert.message }
                </p>
                <div className="d-flex flex-row flex-wrap" style={{ gap: "8px" }}>
                    <AlertBadge title="Eerste constatering">
                        { alert.startDate ? (
                            <DateFormatter date={ alert.startDate }/>
                        ) : "Onbekend" }
                    </AlertBadge>
                    <AlertBadge title="Laatste constatering">
                        { alert.lastSeenDate ? (
                            <DateFormatter date={ alert.lastSeenDate }/>
                        ) : "Onbekend" }
                    </AlertBadge>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AlertCard);
