import React from "react";
import {
    Button
} from "react-bootstrap";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";

function Row({ title, children, className }) {
    return (
        <div className={ "row" + (className ? ` ${className}` : "") }>
            <div className="col-7 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

function RelaySettingsCard({ settings, onClickEdit }) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    Relais { settings.index + 1 }
                </h5>
                <Row title="Naam">
                    { settings.name ?? `Relais ${settings.index + 1}` }
                </Row>
                <Row title="Nominaal vermogen">
                    { settings.nominalPower === null ? (
                        <i className="text-muted">Niet ingesteld</i>
                    ) : (
                        `${settings.nominalPower} W`
                    )}
                </Row>
                <Row title="Minimale aan tijd">
                    { settings.minOnTime } seconden
                </Row>
                <Row title="Automatiseringen" className="mt-3"/>
                <Row title="Aan onder prijs">
                    { settings.alwaysOnBelowPrice === null ? (
                        <i className="fa-solid fa-times text-danger"/>
                    ) : (
                        <React.Fragment>
                            <i className="fa-solid fa-check text-success mr-2"/>
                            &euro;{" "}
                            <NumberFormatter
                                number={ settings.alwaysOnBelowPrice }
                                minimumFractionDigits={ 2 }
                            />
                        </React.Fragment>
                    )}
                </Row>
                <Row title="Aan boven prijs">
                    { settings.alwaysOnAbovePrice === null ? (
                        <i className="fa-solid fa-times text-danger"/>
                    ) : (
                        <React.Fragment>
                            <i className="fa-solid fa-check text-success mr-2"/>
                            &euro;{" "}
                            <NumberFormatter
                                number={ settings.alwaysOnAbovePrice }
                                minimumFractionDigits={ 2 }
                            />
                        </React.Fragment>
                    )}
                </Row>
                <Row title="Overtollige zonne-energie">
                    { !settings.excessPvEnable ? (
                        <i className="fa-solid fa-times text-danger"/>
                    ) : (
                        <i className="fa-solid fa-check text-success"/>
                    )}
                </Row>
                <Row className="mt-3">
                    { onClickEdit && (
                        <Button variant="primary" onClick={ onClickEdit } size="sm">
                            <i className="fa-solid fa-pen mr-2"/>
                            Bewerken
                        </Button>
                    )}
                </Row>
            </div>
        </div>
    );
}

export default React.memo(RelaySettingsCard);
