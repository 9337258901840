import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import InstallationForm from "./detail/components/InstallationForm";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Loading from "../../components/Loading";
import Page404 from "../Page404";
import validateInstallationSettings from "./utility/validateInstallationSettings";
import usePermissions from "../../hooks/usePermissions";

function InstallationAdd() {
    const history = useHistory();
    const permissions = usePermissions();

    const authentication = useContext(AuthenticatedUserContext);

    const [settings, setSettings] = useState({
        name: "",
        type: "private",
        address: "",
        postalCode: "",
        city: "",
        energyPurchasingCost: "",
        minEnergyPriceDeltaForChargeCycle: "",
        lowEnergyPriceThresholdDisableInverter: "",
        lowEnergyPriceThresholdZeroExport: ""
    });
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [company, setCompany] = useState(-1);

    useEffect(() => {
        if(!authentication.user) {
            return;
        }
        if(authentication.user.companies.length === 1) {
            setCompany(authentication.user.companies[0].id);
        }
    }, [authentication]);

    const onSubmit = useMemo(() => {
        return async () => {
            const {
                name,
                type,
                address,
                postalCode,
                city,
                energyPurchasingCost,
                minEnergyPriceDeltaForChargeCycle,
                lowEnergyPriceThresholdDisableInverter,
                lowEnergyPriceThresholdZeroExport
            } = settings;
            if(`${company}` === "-1") {
                setError("Ongeldig bedrijf ingevuld.");
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            const validationError = validateInstallationSettings(settings);
            if(validationError) {
                setError(validationError);
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            try {
                setSaving(true);
                setError(null);
                const response = await axios.post("/addInstallation", {
                    companyId: company,
                    name: name.trim(),
                    type,
                    address: address.trim(),
                    postalCode: postalCode.trim(),
                    city: city.trim(),
                    energyPurchasingCost,
                    minEnergyPriceDeltaForChargeCycle,
                    lowEnergyPriceThresholdDisableInverter: lowEnergyPriceThresholdDisableInverter === "" ? "null" : lowEnergyPriceThresholdDisableInverter,
                    lowEnergyPriceThresholdZeroExport: lowEnergyPriceThresholdZeroExport === "" ? "null" : lowEnergyPriceThresholdZeroExport
                });
                const installation = response.data.installation;
                history.push(`/installation/${installation.id}`);
            } catch(requestError) {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            setSaving(false);
        }
    }, [settings, company, history]);

    if(!permissions.manageInstallations) {
        return (
            <Page404/>
        )
    }
    return (
        <React.Fragment>
            <Title preTitle="Overzicht">
                Installatie aanmaken
            </Title>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { !authentication.user ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    { authentication.user.companies.length !== 1 && (
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">Bedrijf</h5>
                                <div className="form-group mb-0">
                                    <label htmlFor="company">Bedrijf</label>
                                    <select
                                        className="form-control"
                                        value={ company }
                                        onChange={ event => setCompany(event.target.value) }
                                    >
                                        <option value="-1"/>
                                        { authentication.user && authentication.user.companies.map(company => (
                                            <option
                                                key={ company.id }
                                                value={ company.id }
                                            >
                                                { company.name }
                                            </option>
                                        ))}
                                    </select>
                                    <small className="form-text text-muted">
                                        Selecteer hier het bedrijf waaronder de installatie wordt aangemaakt.
                                    </small>
                                </div>
                            </div>
                        </div>
                    )}
                    <InstallationForm
                        settings={ settings }
                        setSettings={ setSettings }
                        saving={ saving }
                    />
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            onClick={ onSubmit }
                            disabled={ saving }
                        >
                            Opslaan
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default React.memo(InstallationAdd);
