import React, {
    useCallback,
    useMemo,
    useState,
    useRef
} from "react";
import moment from "moment";
import {
    Button,
    OverlayTrigger,
    Tooltip,
    Overlay,
    Popover
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";

registerLocale("nl", nl);

function ChartDateSwitcher({ date, setDate, tooltipPlacement = "top" }) {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const dateButtonRef = useRef(null);
    
    const prevDate = useMemo(() => {
        return date.clone().subtract(1, "day");
    }, [date]);
    const nextDate = useMemo(() => {
        return date.clone().add(1, "day");
    }, [date]);

    const setDateNext = useCallback(() => {
        setDate(nextDate);
    }, [setDate, nextDate]);
    const setDateToday = useCallback(() => {
        setDate(moment());
        setShowDatePicker(false);
    }, [setDate]);
    const setDatePrev = useCallback(() => {
        setDate(prevDate);
    }, [setDate, prevDate]);
    
    const handleDateChange = useCallback((selectedDate) => {
        setDate(moment(selectedDate));
        setShowDatePicker(false);
    }, [setDate]);

    const toggleDatePicker = useCallback(() => {
        setShowDatePicker(!showDatePicker);
    }, [showDatePicker]);

    const closeDatePicker = useCallback(() => {
        setShowDatePicker(false);
    }, []);

    const renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div className="d-flex justify-content-between align-items-center px-2 py-2">
            <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className="btn btn-link p-0"
            >
                <i className="fa-solid fa-angle-left" />
            </button>
            
            <select
                className="form-control form-control-sm mx-1"
                value={date.getMonth()}
                onChange={({ target: { value } }) => changeMonth(value)}
                style={{ width: "auto" }}
            >
                {moment.months().map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            
            <select
                className="form-control form-control-sm mx-1"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
                style={{ width: "auto" }}
            >
                {Array.from({ length: 10 }, (_, i) => date.getFullYear() - 5 + i).map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
            
            <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className="btn btn-link p-0"
            >
                <i className="fa-solid fa-angle-right" />
            </button>
        </div>
    );

    return (
        <div className="d-flex flex-row align-items-center w-100">
            <div>
                <OverlayTrigger placement={tooltipPlacement} overlay={
                    <Tooltip id="tooltip-chart-previous">
                        Vorige dag
                    </Tooltip>
                }>
                    <Button
                        variant="link"
                        className="px-0"
                        onClick={setDatePrev}
                    >
                        <i className="fa-solid fa-angle-left mr-md-2"/>
                        <span className="d-none d-md-inline-block">
                            {prevDate.format("DD-MM-YYYY")}
                        </span>
                    </Button>
                </OverlayTrigger>
            </div>
            <div className="flex-grow-1 d-flex justify-content-center text-center" style={{ fontWeight: 500 }}>
                <OverlayTrigger placement={tooltipPlacement} overlay={
                    <Tooltip id="tooltip-chart-today">
                        Selecteer datum
                    </Tooltip>
                }>
                    <Button
                        ref={dateButtonRef}
                        variant="today-link"
                        className="px-0"
                        onClick={toggleDatePicker}
                    >
                        <div className="d-none d-lg-block">
                            {date.format("dddd DD-MM-YYYY")}
                        </div>
                        <div className="d-lg-none">
                            {date.format("dddd")}
                            <br/>
                            {date.format("DD-MM-YYYY")}
                        </div>
                    </Button>
                </OverlayTrigger>
                
                <Overlay
                    show={showDatePicker}
                    target={dateButtonRef.current}
                    placement="bottom"
                    rootClose
                    onHide={closeDatePicker}
                >
                    <Popover id="datepicker-popover" className="date-picker-popover">
                        <Popover.Content className="p-0">
                            <DatePicker
                                selected={date.toDate()}
                                onChange={handleDateChange}
                                inline
                                locale="nl"
                                calendarStartDay={1}
                                renderCustomHeader={renderCustomHeader}
                            />
                            <div className="d-flex justify-content-between p-2 border-top">
                                <Button 
                                    variant="secondary" 
                                    size="sm"
                                    onClick={closeDatePicker}
                                >
                                    Sluiten
                                </Button>
                                <Button 
                                    variant="primary" 
                                    size="sm"
                                    onClick={setDateToday}
                                >
                                    Vandaag
                                </Button>
                            </div>
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
            <div>
                <OverlayTrigger placement={tooltipPlacement} overlay={
                    <Tooltip id="tooltip-chart-next">
                        Volgende dag
                    </Tooltip>
                }>
                    <Button
                        variant="link"
                        className="px-0"
                        onClick={setDateNext}
                    >
                        <span className={`d-none d-md-inline-block ${date.isSame(nextDate, 'day') ? 'text-white' : ''}`}>
                            {nextDate.format("DD-MM-YYYY")}
                        </span>
                        <i className="fa-solid fa-angle-right ml-md-2"/>
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default React.memo(ChartDateSwitcher);
