import React, {
    useMemo
} from "react";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";

function EnergyExchangeSummary({ inTitle, inValue, outTitle, outValue, totalValue }) {
    const columnStyle = useMemo(() => {
        return { width: "85px" };
    }, []);

    return (
        <div className="d-flex flex-column flex-nowrap text-right" style={{ fontSize: "0.85rem", textWrap: "nowrap" }}>
            <div
                className="d-flex flex-row justify-content-end"
                style={{ color: "#0E83AB" }}
            >
                <div className="font-weight-bold">
                    { inTitle }:
                </div>
                <div style={columnStyle}>
                    <NumberFormatter number={inValue} maximumFractionDigits={1} minimumFractionDigits={1}/> kWh
                </div>
            </div>
            <div
                className="d-flex flex-row justify-content-end"
                style={{ color: "#FAA439" }}
            >
                <div className="font-weight-bold">
                    { outTitle }:
                </div>
                <div style={columnStyle}>
                    <NumberFormatter number={-outValue} maximumFractionDigits={1} minimumFractionDigits={1}/> kWh
                </div>
            </div>
            <div className="d-flex flex-row justify-content-end text-muted">
                <div className="font-weight-bold">
                    Totaal:
                </div>
                <div style={columnStyle}>
                    <NumberFormatter number={totalValue} maximumFractionDigits={1} minimumFractionDigits={1}/> kWh
                </div>
            </div>
        </div>
    );
}

export default React.memo(EnergyExchangeSummary);
