import React from "react";

import isNullOrUndefined from "@gridsense/react-energy-flow/lib/utilities/isNullOrUndefined";
import EuroFormatter from "../../../../../components/formatters/EuroFormatter";

function StateBox({ state }) {
    if(state) {
        return (
            <div className="bg-success flex-grow-1 py-1 px-2">
                Aan
            </div>
        );
    } else {
        return (
            <div className="bg-danger flex-grow-1 py-1 px-2">
                Uit
            </div>
        );
    }
}

function PriceBox({ price }) {
    return (
        <div className="bg-secondary py-1 px-2">
            <EuroFormatter price={ price }/>
        </div>
    );
}

function RelaySettingsPriceExample({ settings }) {
    if(!settings) {
        return null;
    }
    return (
        <div className="card mb-3">
            <div className="card-body p-3">
                <p className="text-muted mb-2">
                    Prijs gebaseerde sturing voorbeeld:
                </p>
                <div className="text-center text-white rounded" style={{ overflow: "hidden" }}>
                    { (!isNullOrUndefined(settings.alwaysOnBelowPrice) && isNullOrUndefined(settings.alwaysOnAbovePrice)) ? (
                        <div className="d-flex flex-row">
                            <StateBox state={ true }/>
                            <PriceBox price={ settings.alwaysOnBelowPrice }/>
                            <StateBox state={ false }/>
                        </div>
                    ) : (isNullOrUndefined(settings.alwaysOnBelowPrice) && !isNullOrUndefined(settings.alwaysOnAbovePrice)) ? (
                        <div className="d-flex flex-row">
                            <StateBox state={ false }/>
                            <PriceBox price={ settings.alwaysOnAbovePrice }/>
                            <StateBox state={ true }/>
                        </div>
                    ) : (!isNullOrUndefined(settings.alwaysOnBelowPrice) && !isNullOrUndefined(settings.alwaysOnAbovePrice)) && (
                        <div className="d-flex flex-row">
                            <StateBox state={ settings.alwaysOnBelowPrice <= settings.alwaysOnAbovePrice }/>
                            <PriceBox price={ settings.alwaysOnBelowPrice >= settings.alwaysOnAbovePrice ? settings.alwaysOnAbovePrice : settings.alwaysOnBelowPrice }/>
                            <StateBox state={ settings.alwaysOnBelowPrice >= settings.alwaysOnAbovePrice }/>
                            <PriceBox price={ settings.alwaysOnBelowPrice <= settings.alwaysOnAbovePrice ? settings.alwaysOnAbovePrice : settings.alwaysOnBelowPrice }/>
                            <StateBox state={ settings.alwaysOnBelowPrice <= settings.alwaysOnAbovePrice }/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(RelaySettingsPriceExample);
