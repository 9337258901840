import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import InstallationForm from "./components/InstallationForm";
import validateInstallationSettings from "../utility/validateInstallationSettings";

function InstallationDetailEdit({ installation, setInstallation }) {
    const [settings, setSettings] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if(!installation) {
            return;
        }
        setSettings(installation);
    }, [installation]);

    const onSubmit = useMemo(() => {
        return async () => {
            if(!settings || !installation) {
                return;
            }
            const {
                name,
                type,
                address,
                postalCode,
                city,
                energyPurchasingCost,
                minEnergyPriceDeltaForChargeCycle,
                lowEnergyPriceThresholdDisableInverter,
                lowEnergyPriceThresholdZeroExport
            } = settings;
            const validationError = validateInstallationSettings(settings);
            if(validationError) {
                setError(validationError);
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            try {
                setSaving(true);
                setError(null);
                setSuccess(null);
                const response = await axios.post("/setInstallation", {
                    installationId: installation.id,
                    name: name.trim(),
                    type,
                    address: address.trim(),
                    postalCode: postalCode.trim(),
                    city: city.trim(),
                    energyPurchasingCost,
                    minEnergyPriceDeltaForChargeCycle,
                    lowEnergyPriceThresholdDisableInverter: lowEnergyPriceThresholdDisableInverter === "" ? "null" : lowEnergyPriceThresholdDisableInverter,
                    lowEnergyPriceThresholdZeroExport: lowEnergyPriceThresholdZeroExport === "" ? "null" : lowEnergyPriceThresholdZeroExport
                });
                setInstallation(response.data.installation);
                setSuccess("De wijzigingen zijn opgeslagen.");
                window.scrollTo({ top: 0, behavior: "smooth" });
            } catch(requestError) {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            setSaving(false);
        }
    }, [installation, settings, setInstallation]);

    if(!settings) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <InstallationForm
                settings={ settings }
                setSettings={ setSettings }
                saving={ saving }
            />
            <div className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    onClick={ onSubmit }
                    disabled={ saving }
                >
                    Opslaan
                </Button>
            </div>
        </React.Fragment>
    )
}

export default React.memo(InstallationDetailEdit);
