import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    useHistory
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import ChartCard from "../../components/charts/ChartCard";
import ChartDateSwitcher from "../energy-prices/components/ChartDateSwitcher";
import ChartImbalancePrices from "../../charts/ChartImbalancePrices";
import BalanceDeltaDataTable from "./components/BalanceDeltaDataTable";

function ImbalancePrices({ match }) {
    const [date, setDate] = useState(null);
    const [allPrices, setAllPrices] = useState(null);
    const [prices, setPrices] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if(!match.params.date) {
            setDate(moment());
        }
        setDate(moment(match.params.date));
    }, [match]);
    const dateRange = useMemo(() => {
        if(!date) {
            return null;
        }
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        }
    }, [date]);

    const onDateChange = useCallback((newDate) => {
        history.replace("/imbalance-prices/" + newDate.format("YYYY-MM-DD"));
        setPrices(null);
    }, [history]);

    const updatePrices = useCallback((newPrices) => {
        if(!newPrices) {
            return;
        }
        setAllPrices(newPrices.reverse());
    }, []);

    useEffect(() => {
        if(!allPrices) {
            setPrices(null);
            return;
        }
        const limit = 100;
        if(showAll || allPrices.length <= limit) {
            setPrices(allPrices);
            return;
        }
        setPrices(allPrices.slice(0, limit));
    }, [allPrices, showAll]);

    if(!dateRange) {
        return null;
    }
    return (
        <React.Fragment>
            <Helmet title="Onbalansprijs"/>
            <Title preTitle="Overzicht">
                Onbalansprijs
            </Title>
            <ChartCard
                cardTitle={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                    />
                }
                title={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                        tooltipPlacement="bottom"
                    />
                }
            >
                <ChartImbalancePrices
                    { ...dateRange }
                    updatePrices={ updatePrices }
                />
            </ChartCard>
            { prices && prices.length > 0 && (
                <BalanceDeltaDataTable
                    data={ prices }
                />
            )}
            { allPrices && prices && allPrices.length > 100 && (
                <div className="d-flex justify-content-center">
                    <div>
                        <Button
                            className="mt-3"
                            onClick={ () => setShowAll(!showAll) }
                        >
                            { showAll ? "Toon minder" : "Toon alles" }
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(ImbalancePrices);
