import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";

function BatterySetRatedEnergyModal({ battery, show, handleClose, refreshBatteries }) {
    const [ratedEnergy, setRatedEnergy] = useState(null);
    const [overrideRatedEnergy, setOverrideRatedEnergy] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!show) {
            return;
        }
        setRatedEnergy(battery?.ratedEnergy);
        setOverrideRatedEnergy(battery?.ratedEnergyOverride !== null);
    }, [show, battery]);

    const onSave = useCallback(async () => {
        if(overrideRatedEnergy && ratedEnergy < 1000) {
            const ratedEnergyKilowatt = (ratedEnergy * 0.001).toString().replaceAll(".", ",");
            setError(`De capaciteit moet minimaal 1 kWh zijn. Je hebt nu ${ratedEnergyKilowatt} kWh ingevuld.`);
            return;
        }
        setError(null);
        setSaving(true);
        try {
            await axios.post("/setBatteryRatedEnergy", {
                batteryId: battery.id,
                ratedEnergy: overrideRatedEnergy ? ratedEnergy : null,
            });
            refreshBatteries();
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later nog eens.");
        } finally {
            setSaving(false);
        }
    }, [
        overrideRatedEnergy,
        ratedEnergy,
        battery,
        refreshBatteries,
        handleClose,
    ]);

    return (
        <Modal
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Batterij capaciteit instellen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { (battery?.ratedEnergyOriginal === null && battery?.ratedEnergyOriginal) < 1 ? (
                    <p>
                        De GridSense Gateway kan de capaciteit van de battery niet uitlezen. Vul de capaciteit van de battery in.
                    </p>
                ) : (
                    <p>
                        De GridSense Gateway heeft uit de omvormer gelezen dat de battery een capaciteit van{" "}
                        <NumberFormatter number={ battery?.ratedEnergyOriginal }/> kWh heeft. Hieronder kun je iets
                        anders invullen als dit niet klopt.
                    </p>
                )}
                <div className="custom-control custom-checkbox mb-3">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="overrideRatedEnergy"
                        checked={ overrideRatedEnergy }
                        onChange={ (event) => setOverrideRatedEnergy(event.target.checked) }
                        disabled={ saving }
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="overrideRatedEnergy"
                    >
                        Capaciteit overschrijven
                    </label>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="ratedEnergy">
                        Capaciteit (Wh)
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="ratedEnergy"
                        value={ ratedEnergy }
                        onChange={ (event) => setRatedEnergy(event.target.value) }
                        disabled={ !overrideRatedEnergy || saving }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ saving }
                >
                    Annuleren
                </Button>
                <Button
                    variant="primary"
                    onClick={ onSave }
                    disabled={ saving }
                >
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(BatterySetRatedEnergyModal);
