import React from "react";
import {
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../components/formatters/DateFormatter";

function BalanceDeltaDataTable({ data }) {
    return (
        <Table hover>
            <thead>
            <tr className="tr-sticky-1 tr-vertical-border">
                <th colSpan={2} rowSpan={2} style={{ verticalAlign: "top" }}>Tijdsindicatie</th>
                <th colSpan={8}>Geactiveerd vermogen (MW)</th>
                <th colSpan={3} rowSpan={2} style={{ verticalAlign: "top" }}>Prijsvorming (&euro; / MWh)</th>
            </tr>
            <tr className="tr-sticky-2 tr-vertical-border">
                <th colSpan={2}>
                    aFFR
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Automatic Frequency Restoration Reserve
                        </Tooltip>
                    )}>
                        <i className="fa-solid fa-circle-question ml-2"/>
                    </OverlayTrigger>
                </th>
                <th colSpan={2}>
                    IGCC
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            IGCC is gebaseerd op "netting" van de momentane marktonbalanswaarden van de deelnemende TSO's.
                        </Tooltip>
                    )}>
                        <i className="fa-solid fa-circle-question ml-2"/>
                    </OverlayTrigger>
                </th>
                <th colSpan={2}>
                    mFRRda
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Noodvermogen (Manual Frequency Restoration Reserve)
                        </Tooltip>
                    )}>
                        <i className="fa-solid fa-circle-question ml-2"/>
                    </OverlayTrigger>
                </th>
                <th colSpan={2}>
                    PICASSO
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            PICASSO is het Europese platform voor uitwisseling van balaceringsenergie van aFRR biedingen.
                        </Tooltip>
                    )}>
                        <i className="fa-solid fa-circle-question ml-2"/>
                    </OverlayTrigger>
                </th>
            </tr>
            <tr className="tr-sticky-3">
                <th className="text-center">Volgnr.</th>
                <th className="text-center">Tijd</th>

                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Omvang van het geactiveerde opregelvermogen (som van opregelsignalen).
                        </Tooltip>
                    )}>
                        <span>in</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Omvang van het geactiveerde afregelvermogen (som van afregelsignalen).
                        </Tooltip>
                    )}>
                        <span>uit</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            De levering van vermogen door IGCC aan NL (MW).
                        </Tooltip>
                    )}>
                        <span>in</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            De levering van vermogen door NL aan IGCC (MW).
                        </Tooltip>
                    )}>
                        <span>uit</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Omvang van het geactiveerde noodvermogen dat is ingezet voor een ernstig landelijk vermogenstekort.
                        </Tooltip>
                    )}>
                        <span>in</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Omvang van het geactiveerde noodvermogen dat is ingezet voor een ernstig landelijk vermogensoverschot.
                        </Tooltip>
                    )}>
                        <span>uit</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Nederlands vermogenstekort overgenomen door andere TSO’s of vermogenoverschot overgenomen van andere TSO’s via PICASSO (MW).
                        </Tooltip>
                    )}>
                        <span>in</span>
                    </OverlayTrigger>
                </th>
                <th className="text-center">
                    <OverlayTrigger overlay={(props) => (
                        <Tooltip {...props}>
                            Nederlands vermogenoverschot overgenomen door andere TSO’s of vermogentekort overgenomen van andere TSO’s via PICASSO (MW).
                        </Tooltip>
                    )}>
                        <span>uit</span>
                    </OverlayTrigger>
                </th>

                <th className="text-right">Invoeren</th>
                <th className="text-right">Verschil</th>
                <th className="text-right">Afnemen</th>
            </tr>
            </thead>
            <tbody>
            { data.map((point, index) => {
                const inPrice = point.maxUpwardsRegulationPrice ?? point.minDownwardsRegulationPrice ?? point.midPrice;
                const outPrice = point.minDownwardsRegulationPrice ?? point.maxUpwardsRegulationPrice ?? point.midPrice;
                return (
                    <tr key={ index }>
                        <td className="text-center"><NumberFormatter number={ point.sequence }/></td>
                        <td className="text-center"><DateFormatter date={ point.timeIntervalStart } format="HH:mm"/></td>

                        <td className="text-center"><NumberFormatter number={ point.powerAfrrIn }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerAfrrOut }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerIgccIn }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerIgccOut }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerMfrrdaIn }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerMfrrdaOut }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerPicassoIn }/></td>
                        <td className="text-center"><NumberFormatter number={ point.powerPicassoOut }/></td>

                        <td className={ "text-right " + (inPrice < 0 ? "text-danger" : "text-success") }>
                            <NumberFormatter
                                number={ inPrice }
                                minimumFractionDigits={ 2 }
                            />
                        </td>
                        <td className="text-right">
                            { inPrice === outPrice ? (
                                <i className="fa-solid fa-equals fa-fw"/>
                            ) : (
                                <i className="fa-solid fa-not-equal fa-fw text-danger"/>
                            )}
                        </td>
                        <td className={ "text-right " + (outPrice < 0 ? "text-success" : "text-danger") }>
                            <NumberFormatter
                                number={ outPrice }
                                minimumFractionDigits={ 2 }
                            />
                        </td>
                    </tr>
                );
            }) }
            </tbody>
        </Table>
    );
}

export default React.memo(BalanceDeltaDataTable);
