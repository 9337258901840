import React from "react";
import {
    Title
} from "@zandor300/backoffice-framework";

import AlertsPageContent from "./components/AlertsPageContent";

function Alerts() {
    return (
        <React.Fragment>
            <Title preTitle="Overzicht">
                Alerts
            </Title>
            <AlertsPageContent
                apiEndpoint="/getAlerts"
                requestParameters={{}}
                showInstallation
            />
        </React.Fragment>
    );
}

export default React.memo(Alerts);
