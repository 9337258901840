import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function AlertStatus({ alert }) {
    return (
        <OverlayTrigger overlay={(props) => (
            <Tooltip {...props}>
                { alert.open ? "Actieve alert" : "Gesloten alert" }
            </Tooltip>
        )}>
            { alert.open ? (
                <i className="fa-solid fa-circle-exclamation text-danger"/>
            ) : (
                <i className="fa-solid fa-circle-check text-success"/>
            )}
        </OverlayTrigger>
    )
}

export default React.memo(AlertStatus);
