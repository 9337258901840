import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartDateSwitcher from "../../../energy-prices/components/ChartDateSwitcher";
import ChartLoading from "../../../../components/charts/ChartLoading";
import ChartNoData from "../../../../components/charts/ChartNoData";
import ChartCard from "../../../../components/charts/ChartCard";
import HistoryResolution from "../../../../utilities/HistoryResolution";
import EuroFormatter from "../../../../components/formatters/EuroFormatter";
import EnergyMeterHistoryPriceSummary from "./history/EnergyMeterHistoryPriceSummary";
import EnergyExchangeSummary from "./history/EnergyExchangeSummary";

function EnergyMeterHistoryModal({ energyMeter, show, handleClose }) {
    const [energyMeterId, setEnergyMeterId] = useState(null);
    const [date, setDate] = useState(moment().tz("Europe/Amsterdam", true));
    const [historyPower, setHistoryPower] = useState(null);
    const [historyEnergy, setHistoryEnergy] = useState(null);
    const [historyEnergyMetadata, setHistoryEnergyMetadata] = useState(null);
    const [historyPrice, setHistoryPrice] = useState(null);
    const [historyPriceMetadata, setHistoryPriceMetadata] = useState(null);
    const [error, setError] = useState(null);

    const isImportExportMeter = useMemo(() => {
        return energyMeter?.options?.includes("Import")
            && energyMeter?.options?.includes("Export");
    }, [energyMeter]);

    const onDateChange = useCallback((newDate) => {
        setDate(newDate);
    }, []);
    const chartDateRange = useMemo(() => {
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day"),
        };
    }, [date]);

    useEffect(() => {
        if(!show) {
            return;
        }
        onDateChange(moment().tz("Europe/Amsterdam", true));
    }, [show, onDateChange]);
    useEffect(() => {
        setEnergyMeterId(energyMeter.id);
    }, [energyMeter]);

    const refreshHistoryPower = useCallback(async () => {
        setHistoryPower(null);
        setError(null);
        if(!energyMeterId) {
            return;
        }
        try {
            const response = await axios.post("/getEnergyMeterHistory", {
                energyMeterId: energyMeterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString(),
                resolution: HistoryResolution.FIVE_MINUTES.value,
            });
            setHistoryPower(response.data.history);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [energyMeterId, chartDateRange]);
    const refreshHistoryEnergy = useCallback(async () => {
        setHistoryEnergy(null);
        setHistoryEnergyMetadata(null);
        setError(null);
        if(!energyMeterId) {
            return;
        }
        try {
            const response = await axios.post("/getEnergyMeterHistory", {
                energyMeterId: energyMeterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString(),
                resolution: HistoryResolution.ONE_HOUR.value,
            });
            setHistoryEnergy(response.data.history);
            setHistoryEnergyMetadata({
                totalEnergyImport: response.data.totalEnergyImport,
                totalEnergyExport: response.data.totalEnergyExport,
                totalEnergy: response.data.totalEnergy,
            });
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [energyMeterId, chartDateRange]);
    const refreshHistoryPrice = useCallback(async () => {
        setHistoryPrice(null);
        setHistoryPriceMetadata(null);
        setError(null);
        if(!energyMeterId) {
            return;
        }
        try {
            const response = await axios.post("/getEnergyMeterHistoryPrice", {
                energyMeterId: energyMeterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString(),
                resolution: HistoryResolution.ONE_HOUR.value,
            });
            setHistoryPrice(response.data.history);
            setHistoryPriceMetadata({
                totalExportCost: response.data.totalExportCost,
                totalImportCost: response.data.totalImportCost,
                totalCost: response.data.totalCost,
            });
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [energyMeterId, chartDateRange]);

    useEffect(() => {
        if(!show) {
            return;
        }
        refreshHistoryPower();
        refreshHistoryEnergy();
        refreshHistoryPrice();
    }, [show, refreshHistoryPower, refreshHistoryEnergy, refreshHistoryPrice]);

    const dataPower = useMemo(() => {
        if(!historyPower) {
            return null;
        }
        if(historyPower.length === 0 || historyPower.filter(({ energyImport, energyExport }) => {
            return energyImport > 0 || energyExport > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Import",
                data: historyPower.map(({ date, energyImportAvgPower }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyImportAvgPower,
                })),
            },
            {
                label: "Export",
                data: historyPower.map(({ date, energyExportAvgPower }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyExportAvgPower,
                })),
            },
        ];
    }, [historyPower]);
    const dataEnergy = useMemo(() => {
        if(!historyEnergy) {
            return null;
        }
        if(historyEnergy.length === 0 || historyEnergy.filter(({ energyImport, energyExport }) => {
            return energyImport > 0 || energyExport > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Import",
                data: historyEnergy.map(({ date, energyImport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyImport * HistoryResolution.ONE_HOUR.adjustEnergy,
                })),
            },
            {
                label: "Export",
                data: historyEnergy.map(({ date, energyExport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyExport * HistoryResolution.ONE_HOUR.adjustEnergy,
                })),
            }
        ];
    }, [historyEnergy]);
    const dataPrice = useMemo(() => {
        if(!historyPrice) {
            return null;
        }
        if(historyPrice.length === 0 || historyPrice.filter(({ energyImport, energyExport }) => {
            return energyImport > 0 || energyExport > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Import",
                data: historyPrice.map(({ date, costImport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: costImport,
                })),
            },
            {
                label: "Export",
                data: historyPrice.map(({ date, costExport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -costExport,
                })),
            }
        ];
    }, [historyPrice]);
    const acVoltageData = useMemo(() => {
        if(!historyPower) {
            return null;
        }
        if(historyPower.length === 0 || historyPower.filter(({ voltageAcL1, voltageAcL2, voltageAcL3 }) => {
            return voltageAcL1 !== null || voltageAcL2 !== null || voltageAcL3 !== null;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "AC Spanning L1",
                data: historyPower.map(({ date, voltageAcL1 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL1
                }))
            }, {
                label: "AC Spanning L2",
                data: historyPower.map(({ date, voltageAcL2 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL2
                }))
            }, {
                label: "AC Spanning L3",
                data: historyPower.map(({ date, voltageAcL3 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL3
                }))
            }
        ];
    }, [historyPower]);
    const acVoltagePhaseData = useMemo(() => {
        if(!historyPower) {
            return null;
        }
        if(historyPower.length === 0 || historyPower.filter(({ voltageAcL1L2, voltageAcL2L3, voltageAcL3L1 }) => {
            return voltageAcL1L2 !== null || voltageAcL2L3 !== null || voltageAcL3L1 !== null;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "AC Spanning L1-L2",
                data: historyPower.map(({ date, voltageAcL1L2 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL1L2
                }))
            }, {
                label: "AC Spanning L2-L3",
                data: historyPower.map(({ date, voltageAcL2L3 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL2L3
                }))
            }, {
                label: "AC Spanning L3-L1",
                data: historyPower.map(({ date, voltageAcL3L1 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL3L1
                }))
            }
        ];
    }, [historyPower]);
    const primaryAxisPower = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm"),
        },
        min: moment(chartDateRange.startDate).toDate(),
        max: moment(chartDateRange.endDate).toDate(),
    }), [chartDateRange]);
    const secondaryAxesPower = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001).toFixed(2)} kW`,
            tooltip: (value) => `${(value * 0.001).toFixed(2)} kW`,
        },
        stacked: true,
    }], []);
    const primaryAxisEnergy = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("YYYY-MM-DD HH:mm:ss"),
        formatters: {
            scale: (key) => moment(key).format("HH:00"),
            tooltip: (key) => moment(key).format("HH:00 - HH:59"),
        }
    }), []);
    const secondaryAxesEnergy = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001).toFixed(2)} kWh`,
            tooltip: (value) => `${Math.abs(value * 0.001).toFixed(2)} kWh`,
        },
    }], []);
    const primaryAxisPrice = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("YYYY-MM-DD HH:mm:ss"),
        formatters: {
            scale: (key) => moment(key).format("HH:00"),
            tooltip: (key) => moment(key).format("HH:00 - HH:59"),
        }
    }), []);
    const secondaryAxesPrice = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => <EuroFormatter price={value} />,
            tooltip: (value) => <EuroFormatter price={value} />,
        },
    }], []);
    const secondaryAxesVoltage = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${value?.toFixed(0)} V`,
            tooltip: (value) => `${value?.toFixed(1)} V`
        },
    }], []);

    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Energiemeter data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-3">
                    <div className="card-body">
                        <ChartDateSwitcher
                            date={ date }
                            setDate={ onDateChange }
                        />
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : (
                    <React.Fragment>
                        <ChartCard title={`${isImportExportMeter ? "Import / Export" : "AC"} vermogen (kW)`}>
                            { !historyPower ? (
                                <ChartLoading/>
                            ) : historyPower.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: dataPower,
                                        primaryAxis: primaryAxisPower,
                                        secondaryAxes: secondaryAxesPower,
                                    }}
                                />
                            )}
                        </ChartCard>
                        { isImportExportMeter && (
                            <ChartCard title={
                                <div className="d-flex flex-row w-100">
                                    <div className="flex-grow-1">
                                        Import / Export energie (kWh)
                                    </div>
                                    { historyEnergyMetadata && (
                                        <EnergyExchangeSummary
                                            inTitle="Import"
                                            inValue={ historyEnergyMetadata.totalEnergyImport * 0.001 }
                                            outTitle="Export"
                                            outValue={ historyEnergyMetadata.totalEnergyExport * 0.001 }
                                            totalValue={ historyEnergyMetadata.totalEnergy * 0.001 }
                                        />
                                    )}
                                </div>
                            }>
                                { !historyEnergy ? (
                                    <ChartLoading/>
                                ) : historyEnergy.length === 0 ? (
                                    <ChartNoData/>
                                ) : (
                                    <Chart
                                        options={{
                                            data: dataEnergy,
                                            primaryAxis: primaryAxisEnergy,
                                            secondaryAxes: secondaryAxesEnergy,
                                        }}
                                    />
                                )}
                            </ChartCard>
                        )}
                        { isImportExportMeter && (
                            <ChartCard title={
                                <div className="d-flex flex-row w-100">
                                    <div className="d-flex flex-column w-100 flex-grow-1">
                                        Import / Export prijs (&euro;)
                                        <small className="text-muted" style={{ fontSize: "0.85rem" }}>
                                            exclusief btw, inclusief inkoopkosten en energiebelasting
                                        </small>
                                    </div>
                                    <EnergyMeterHistoryPriceSummary historyPriceMetadata={ historyPriceMetadata }/>
                                </div>
                            }>
                                { !historyPrice ? (
                                    <ChartLoading/>
                                ) : historyPrice.length === 0 ? (
                                    <ChartNoData/>
                                ) : (
                                    <Chart
                                        options={{
                                            data: dataPrice,
                                            primaryAxis: primaryAxisPrice,
                                            secondaryAxes: secondaryAxesPrice,
                                        }}
                                    />
                                )}
                            </ChartCard>
                        )}
                        <ChartCard title="AC Spanning (L-N) (V)">
                            { !acVoltageData ? (
                                <ChartLoading/>
                            ) : acVoltageData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: acVoltageData,
                                        primaryAxis: primaryAxisPower,
                                        secondaryAxes: secondaryAxesVoltage
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="AC Spanning (L-L) (V)">
                            { !acVoltagePhaseData ? (
                                <ChartLoading/>
                            ) : acVoltagePhaseData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: acVoltagePhaseData,
                                        primaryAxis: primaryAxisPower,
                                        secondaryAxes: secondaryAxesVoltage
                                    }}
                                />
                            )}
                        </ChartCard>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(EnergyMeterHistoryModal);
