import React, {
    useMemo
} from "react";

import EuroFormatter from "../../../../../components/formatters/EuroFormatter";

function EnergyMeterHistoryPriceSummary({ historyPriceMetadata }) {
    const columnStyle = useMemo(() => {
        return { width: "65px" };
    }, []);

    if(!historyPriceMetadata) {
        return null;
    }

    return (
        <div className="d-flex flex-column flex-nowrap text-right" style={{ fontSize: "0.85rem", textWrap: "nowrap" }}>
            <div
                className="d-flex flex-row justify-content-end text-muted font-weight-bold"
            >
                <div style={columnStyle}>
                    excl. btw
                </div>
                <div style={columnStyle}>
                    incl. btw
                </div>
            </div>
            <div
                className="d-flex flex-row justify-content-end"
                style={{ color: "#0E83AB" }}
            >
                <div className="font-weight-bold">
                    Import:
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={historyPriceMetadata.totalImportCost}/>
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={historyPriceMetadata.totalImportCost * 1.21}/>
                </div>
            </div>
            <div
                className="d-flex flex-row justify-content-end"
                style={{ color: "#FAA439" }}
            >
                <div className="font-weight-bold">
                    Export:
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={-historyPriceMetadata.totalExportCost}/>
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={-historyPriceMetadata.totalExportCost * 1.21}/>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-end text-muted">
                <div className="font-weight-bold">
                    Totaal:
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={historyPriceMetadata.totalCost}/>
                </div>
                <div style={columnStyle}>
                    <EuroFormatter price={historyPriceMetadata.totalCost * 1.21}/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(EnergyMeterHistoryPriceSummary);
