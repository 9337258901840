import React from "react";

function AlertBadge({ title, children }) {
    return (
        <div>
            <div className="badge badge-pill badge-secondary">
                {title}
                <div className="badge badge-pill badge-subbadge badge-light">
                    { children }
                </div>
            </div>
        </div>
    );
}

export default React.memo(AlertBadge);
