import React from "react";

import AlertsPageContent from "../../alerts/components/AlertsPageContent";

function InstallationDetailAlerts({ installation }) {
    return (
        <AlertsPageContent
            apiEndpoint="/getInstallationAlerts"
            requestParameters={ installation && { installationId: installation.id } }
        />
    );
}

export default React.memo(InstallationDetailAlerts);
