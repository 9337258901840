import React from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import DeviceIcon from "../../../../../components/DeviceIcon";
import DateFormatter from "../../../../../components/formatters/DateFormatter";
import usePermissions from "../../../../../hooks/usePermissions";
import DeviceCardRow from "./components/DeviceCardRow";

function InstallationGatewayCard({ gateway, installation, onClickRestart, onClickSettings }) {
    const permissions = usePermissions();

    return (
        <div className="card mb-3" key={ gateway.id }>
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            Gateway
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { gateway.uuid }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3">
                        <DeviceIcon deviceType="gateway"/>
                    </div>
                </div>
                <DeviceCardRow title="Laatst gezien">
                    <DateFormatter date={ gateway.lastSeenDate }/>
                </DeviceCardRow>

                <div className="d-flex flex-row justify-content-end">
                    { permissions.globalAdmin && (
                        <OverlayTrigger overlay={
                            <Tooltip id={ `balena-${gateway.id}` }>
                                Open gateway in Balena-cloud
                            </Tooltip>
                        }>
                            <a
                                href={ `https://dashboard.balena-cloud.com/devices/${gateway.uuid}` }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary btn-sm mt-2 ml-2"
                            >
                                <i className="fa-solid fa-cube fa-fw"/>
                            </a>
                        </OverlayTrigger>
                    )}
                    { installation.userRole.permissions.manageInstallationDevices && (
                        <React.Fragment>
                            <OverlayTrigger overlay={
                                <Tooltip id={ `settings-${gateway.id}` }>
                                    Instellingen wijzigen
                                </Tooltip>
                            }>
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={ () => onClickSettings(gateway) }
                                    className="ml-2 mt-2"
                                >
                                    <i className="fa-solid fa-gear fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={
                                <Tooltip id={ `restart-software-${gateway.id}` }>
                                    Herstart software
                                </Tooltip>
                            }>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={ () => onClickRestart(gateway) }
                                    className="ml-2 mt-2"
                                >
                                    <i className="fa-solid fa-rotate-right fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(InstallationGatewayCard);
